import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Form = styled.form`
	display: flex;
	flex-direction: column;
	max-width: 30rem;
	margin: 0 auto;

	& .MuiFormLabel-root.Mui-focused {
		color: var(--primary-color) !important;
	}
`;

const errorMessage = styled.p`
	font-size: 0.8rem;
	color: var(--error-color);
`;

const Input = styled(TextField)`
	margin: 1rem 0 !important;

	& .MuiInput-underline:after {
		border-color: var(--primary-color) !important;
	}
`;

const SubmitButton = styled(Button)`
	color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
	max-width: 10rem;
`;
const RecaptchaLegend = styled.legend`
	font-size: 0.65rem;
	margin: 1rem 0 2rem;
`;

export { Form, Input, SubmitButton, errorMessage, RecaptchaLegend };
