import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import Img from 'gatsby-image';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import Mailto from 'react-protected-mailto';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { Form, Input, SubmitButton, RecaptchaLegend } from '../components/styles/forms';

const ContactPage = ({ data, location }) => {
	const [fullname, setFormName] = useState('');
	const [email, setFormEmail] = useState('');
	const [message, setFormMessage] = useState('');
	const [recaptchaResponse, setRecaptchaResponse] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formErrors, setFormError] = useState([]);

	useEffect(() => {
		process.env.GATSBY_RECAPTCHA_KEY && loadReCaptcha(process.env.GATSBY_RECAPTCHA_KEY);
	});

	const sendToCF7 = async e => {
		e.preventDefault();
		setFormError([]);
		(!fullname || fullname.length < 3) && setFormError([...formErrors, 'fullname']);
		!email && setFormError([...formErrors, 'email']);

		(!message || message.length < 3) && setFormError([...formErrors, 'message']);
		!recaptchaResponse && setFormError([...formErrors, 'robot']);
		if (!formErrors.length) {
			// Post to wp
			const params = new URLSearchParams();
			params.append('fullname', fullname);
			params.append('email', email);
			params.append('message', message);
			params.append('g-recaptcha-response', recaptchaResponse);
			const response = await axios.post(
				'https://data.nessatoye.com/wp-json/contact-form-7/v1/contact-forms/51/feedback',
				params
			);
			setFormSubmitted(response.data.message);
		}
	};

	const verifyCallback = async response => {
		if (!response) {
			return setRecaptchaResponse(false);
		}
		setRecaptchaResponse(response);
	};

	return (
		<Layout location={location}>
			<SEO title='Want to know more, contact me' />
			{formSubmitted ? (
				<>
					<h3>{formSubmitted}</h3>
					<Img fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid} />
				</>
			) : (
				<Form onSubmit={sendToCF7}>
					<legend>
						<p>
							Are you the secret keeper of a good story? Feel free to contact Andrea Morrison, my agent from Writers
							House at <Mailto email='amorisson@writershouse.com' />
						</p>
						<p>
							For any question, request or just want to say hello, fill-in the following form and I'll be happy to
							answer you!
						</p>
					</legend>
					<Input
						error={formErrors.includes('fullname')}
						helperText={formErrors.includes('fullname') ? 'Please enter a valid name' : ''}
						type='text'
						name='fullname'
						required
						value={fullname}
						label='Your Name (required)'
						onChange={event => setFormName(event.target.value)}
					/>

					<Input
						error={formErrors.includes('email')}
						helperText={formErrors.includes('email') ? 'Please enter a valid email' : ''}
						required
						type='email'
						name='email'
						value={email}
						label='Your Email (required)'
						onChange={event => setFormEmail(event.target.value)}
					/>
					<Input
						error={formErrors.includes('message')}
						helperText={formErrors.includes('message') ? 'Please enter a message' : ''}
						name='message'
						required
						value={message}
						label='Message (required)'
						multiline
						rows='4'
						onChange={event => setFormMessage(event.target.value)}
					/>
					{process.env.GATSBY_RECAPTCHA_KEY && (
						<ReCaptcha sitekey={process.env.GATSBY_RECAPTCHA_KEY} verifyCallback={verifyCallback} />
					)}
					<RecaptchaLegend>
						This site is protected by reCAPTCHA and the Google{' '}
						<a target='_blank' rel='noopener noreferrer' href='https://policies.google.com/privacy'>
							Privacy Policy
						</a>{' '}
						and{' '}
						<a target='_blank' rel='noopener noreferrer' href='https://policies.google.com/terms'>
							Terms of Service
						</a>{' '}
						apply.
					</RecaptchaLegend>
					<SubmitButton type='submit' variant='outlined'>
						Send message
					</SubmitButton>
				</Form>
			)}
		</Layout>
	);
};

export default ContactPage;
// Set here the ID of the home page.
export const pageQuery = graphql`
	query {
		wordpressPage(wordpress_id: { eq: 22 }) {
			content
			title
			featured_media {
				localFile {
					childImageSharp {
						fluid(maxWidth: 1280) {
							...GatsbyImageSharpFluid_withWebp
						}
						sizes {
							presentationHeight
							presentationWidth
						}
					}
				}
			}
		}
	}
`;
